import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import {
  BootstrapTextInput,
  BootstrapToggleInput,
  SaveButton,
  TypeaheadSingleInput,
  TypeaheadTagsInput,
  FormCard,
} from '../../components/forms';
import { _CONTENTFUL_CONFIG } from '../../utils/empties';
import {
  useAllQuery,
  useSingleQuery,
} from '../../utils/hooks/reactQuery/queries';
import { useItemMutation } from '../../utils/hooks/reactQuery/mutations';

const ContentfulConfigSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  tags: Yup.array().of(Yup.string()),
  authorId: Yup.string().required('Author is required'),
  categoryId: Yup.string().required('Category is required'),
  publicationId: Yup.string().required('Publication is required'),
  portfolio: Yup.string(),
  pro: Yup.boolean(),
  lifetime: Yup.boolean(),
});

function ContentfulConfig() {
  const { id } = useParams();
  const {
    data = _CONTENTFUL_CONFIG,
    isLoading,
    isError,
    error,
  } = useSingleQuery('contentful-config', id);
  const { add, update } = useItemMutation('contentful-config');
  const { data: authors = [] } = useAllQuery('contentful-authors');
  const { data: categories = [] } = useAllQuery(
    'contentful-article-categories',
  );
  const { data: publications = [] } = useAllQuery('contentful-publications');

  const handleSubmit = (values, { setSubmitting }) => {
    const toastId = toast.loading('Saving web config...');

    // Are we updating or adding?
    if (id !== 'new') {
      update.mutate(values, {
        onSuccess: () => {
          console.log('Successfully Updated!');
          toast.update(toastId, {
            render: 'Saved web config!',
            type: 'success',
            isLoading: false,
            autoClose: 3000,
          });
          setSubmitting(false);
        },
        onError: (error) => {
          console.error(error);
          toast.update(toastId, {
            render: 'Could not save web config...',
            type: 'error',
            isLoading: false,
            autoClose: 3000,
          });
          setSubmitting(false);
        },
      });
    } else {
      add.mutate(values, {
        onSuccess: () => {
          console.log('Successfully Created!');
          toast.update(toastId, {
            render: 'Saved web config!',
            type: 'success',
            isLoading: false,
            autoClose: 3000,
          });
        },
        onError: (error) => {
          console.error(error);
          toast.update(toastId, {
            render: 'Could not save web config...',
            type: 'error',
            isLoading: false,
            autoClose: 3000,
          });
          setSubmitting(false);
        },
      });
    }
  };

  return (
    <Container fluid>
      {isLoading ? (
        <h5>Loading...</h5>
      ) : isError ? (
        <h5>Error: {error.message}</h5>
      ) : (
        <FormCard
          breadcrumbLink="/dashboard/contentful-configs"
          breadcrumbLabel="Contentful Configs"
          breadcrumbItem={
            data.name.length > 0 ? data.name : 'New Contentful Config'
          }
        >
          <Formik
            initialValues={data}
            validationSchema={ContentfulConfigSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ isValid, isSubmitting, values }) => (
              <Form>
                <Row>
                  <BootstrapTextInput
                    label="Name"
                    id="name"
                    name="name"
                    placeholder="Name"
                  />
                  <TypeaheadTagsInput />
                </Row>
                <Row>
                  <TypeaheadSingleInput
                    label="Author"
                    id="authorId"
                    name="authorId"
                    placeholder="Author"
                    options={authors}
                    objects
                    labelKey="displayName"
                    valueKey="_id"
                  />
                  <TypeaheadSingleInput
                    label="Article Category"
                    id="categoryId"
                    name="categoryId"
                    placeholder="Article Category"
                    options={categories}
                    objects
                    labelKey="title"
                    valueKey="_id"
                    renderMenuItemChildren={(option) => (
                      <div>
                        {option.title}
                        <div>
                          <small>Slug: {option.slug}</small>
                        </div>
                      </div>
                    )}
                  />
                  <TypeaheadSingleInput
                    label="Publication"
                    id="publicationId"
                    name="publicationId"
                    placeholder="Publication"
                    options={publications}
                    objects
                    labelKey="itemCode"
                    valueKey="_id"
                    renderMenuItemChildren={(option) => (
                      <div>
                        {option.itemCode}
                        <div>
                          <small>Type: {option.type}</small>
                        </div>
                      </div>
                    )}
                  />
                  <BootstrapTextInput
                    label="Portfolio"
                    id="portfolio"
                    name="portfolio"
                    placeholder="Portfolio"
                  />
                  <BootstrapToggleInput
                    fullWidth
                    label="Lifetime"
                    id="lifetime"
                    name="lifetime"
                    labelChecked="Requires Lifetime Membership."
                    labelUnchecked="Does NOT Require Lifetime Membership."
                  />
                  <BootstrapToggleInput
                    label="Pro"
                    id="pro"
                    name="pro"
                    labelChecked="Requires Pro Membership."
                    labelUnchecked="Does NOT Require Pro Membership."
                  />
                </Row>
                <SaveButton disabled={isSubmitting || !isValid} />
              </Form>
            )}
          </Formik>
        </FormCard>
      )}
    </Container>
  );
}

export default ContentfulConfig;
