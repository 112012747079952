import {
  faArrowUpRightFromSquare,
  faBrowser,
  faEnvelope,
  faMessageSms,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Row, Table, Button } from 'reactstrap';
import WebStatusRow from '../../../components/WebStatusRow';

function ReviewAndLaunch({ status }) {
  return (
    <Col className="px-3">
      {status.bsftEmailTemplate?.uuid && (
        <Row>
          <Col>
            <div className="d-flex align-items-center text-cpblue-300">
              <FontAwesomeIcon icon={faEnvelope} size="3x" />
              <h4 className="mb-0 ms-2">EMAIL</h4>
            </div>
            <Table responsive striped borderless className="mt-3">
              <thead>
                <tr>
                  <th>Template Name</th>
                  <th>Created At</th>
                  <th>Updated At</th>
                  <th>UUID</th>
                  <th className="text-center">Open</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="align-middle">
                    {status.bsftEmailTemplate.name}
                  </td>
                  <td className="align-middle" style={{ whiteSpace: 'nowrap' }}>
                    {new Date(
                      status.bsftEmailTemplate.created_at,
                    ).toLocaleString([], {
                      day: 'numeric',
                      year: 'numeric',
                      month: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </td>
                  <td className="align-middle" style={{ whiteSpace: 'nowrap' }}>
                    {new Date(
                      status.bsftEmailTemplate.updated_at,
                    ).toLocaleString([], {
                      day: 'numeric',
                      year: 'numeric',
                      month: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </td>
                  <td className="align-middle" style={{ whiteSpace: 'nowrap' }}>
                    {status.bsftEmailTemplate.uuid}
                  </td>
                  <td className="align-middle">
                    <Button
                      color="cpblue-500"
                      className="rounded-circle d-block mx-auto"
                      onClick={() =>
                        window.open(
                          `https://app.getblueshift.com/dashboard#/app/email_template_studio/${status.bsftEmailTemplate.uuid}/edit/templates/html`,
                          '_blank',
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
      {Object.keys(status.bsftEmailCampaigns || {}).length > 0 && (
        <Row className="mb-3">
          <Col>
            <hr />
            <Table responsive striped borderless>
              <thead>
                <tr>
                  <th>Campaign Name</th>
                  <th>Start Date</th>
                  <th>UUID</th>
                  <th className="text-center">Open</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(status.bsftEmailCampaigns).map((key) => (
                  <tr key={key}>
                    <td className="align-middle">
                      {status.bsftEmailCampaigns[key].campaign.name}
                    </td>
                    <td
                      className="align-middle"
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      {new Date(
                        status.bsftEmailCampaigns[key].campaign.startdate,
                      ).toLocaleString([], {
                        day: 'numeric',
                        year: 'numeric',
                        month: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                    </td>
                    <td
                      className="align-middle"
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      {status.bsftEmailCampaigns[key].campaign.uuid}
                    </td>
                    <td className="align-middle">
                      <Button
                        color="cpblue-500"
                        className="rounded-circle d-block mx-auto"
                        onClick={() =>
                          window.open(
                            `https://app.getblueshift.com/dashboard#/app/campaigns/${status.bsftEmailCampaigns[key].campaign.uuid}/edit_v3/journey?tid=7e61b3f1-af27-4ac3-80f9-c58a5513999b&detail_tab=experiment&view_mode=DEFAULT`,
                            '_blank',
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
      {Object.keys(status.bsftSmsTemplates || {}).length > 0 && (
        <Row>
          <Col>
            <div className="d-flex align-items-center text-cpblue-300">
              <FontAwesomeIcon icon={faMessageSms} size="3x" />
              <h4 className="mb-0 ms-2">SMS</h4>
            </div>
            <Table responsive striped borderless className="mt-3">
              <thead>
                <tr>
                  <th>Template Name</th>
                  <th>Created At</th>
                  <th>Updated At</th>
                  <th>UUID</th>
                  <th className="text-center">Open</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(status.bsftSmsTemplates).map((key) => (
                  <tr>
                    <td className="align-middle">
                      {status.bsftSmsTemplates[key].name}
                    </td>
                    <td
                      className="align-middle"
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      {new Date(
                        status.bsftSmsTemplates[key].created_at,
                      ).toLocaleString([], {
                        day: 'numeric',
                        year: 'numeric',
                        month: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                    </td>
                    <td
                      className="align-middle"
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      {new Date(
                        status.bsftSmsTemplates[key].updated_at,
                      ).toLocaleString([], {
                        day: 'numeric',
                        year: 'numeric',
                        month: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                    </td>
                    <td
                      className="align-middle"
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      {status.bsftSmsTemplates[key].uuid}
                    </td>
                    <td className="align-middle">
                      <Button
                        color="cpblue-500"
                        className="rounded-circle d-block mx-auto"
                        onClick={() =>
                          window.open(
                            `https://app.getblueshift.com/dashboard#/app/sms_template/${status.bsftSmsTemplates[key].uuid}/edit/content`,
                            '_blank',
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
      {Object.keys(status.bsftSmsCampaigns || {}).length > 0 && (
        <Row className="mb-3">
          <Col>
            <hr />
            <Table responsive striped borderless>
              <thead>
                <tr>
                  <th>Campaign Name</th>
                  <th>Start Date</th>
                  <th>UUID</th>
                  <th className="text-center">Open</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(status.bsftSmsTemplates).map((key) => (
                  <tr>
                    <td className="align-middle">
                      {status.bsftSmsCampaigns[key].campaign.name}
                    </td>
                    <td
                      className="align-middle"
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      {new Date(
                        status.bsftSmsCampaigns[key].campaign.startdate,
                      ).toLocaleString([], {
                        day: 'numeric',
                        year: 'numeric',
                        month: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                    </td>
                    <td
                      className="align-middle"
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      {status.bsftSmsCampaigns[key].campaign.uuid}
                    </td>
                    <td className="align-middle">
                      <Button
                        color="cpblue-500"
                        className="rounded-circle d-block mx-auto"
                        onClick={() =>
                          window.open(
                            `https://app.getblueshift.com/dashboard#/app/sms_template/${status.bsftSmsCampaigns[key].campaign.uuid}/edit/content`,
                            '_blank',
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
      {Object.keys(status.webPosts || {}).length > 0 && (
        <Row className="mb-3">
          <Col>
            <div className="d-flex align-items-center text-cpblue-300">
              <FontAwesomeIcon icon={faBrowser} size="3x" />
              <h4 className="mb-0 ms-2">WEB</h4>
            </div>
            <Table responsive striped borderless className="mt-3">
              <thead>
                <tr>
                  <th>Web Post Name</th>
                  <th>Headline</th>
                  <th>Scheduled Date</th>
                  <th>Modified At</th>
                  <th>Status</th>
                  <th className="text-center">Open</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(status.webPosts).map((key) => (
                  <WebStatusRow
                    key={key}
                    name={key}
                    status={status.webPosts[key]}
                  />
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
    </Col>
  );
}

export default ReviewAndLaunch;
