import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-duotone-svg-icons';
import { buildLink } from '../utils/webPosts';

function WebStatusRow({ name, status }) {
  const isWp = status.type === 'WpConfig';
  const data = status.entry || status.data;
  return (
    <tr>
      <td className="align-middle">{name.toUpperCase()}</td>
      <td className="align-middle">
        {isWp ? data.title.rendered : data.fields.title['en-US']}
      </td>
      <td className="align-middle" style={{ whiteSpace: 'nowrap' }}>
        {new Date(
          isWp ? data.date : data.fields.postDate['en-US'],
        ).toLocaleString([], {
          day: 'numeric',
          year: 'numeric',
          month: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        })}
      </td>
      <td className="align-middle" style={{ whiteSpace: 'nowrap' }}>
        {new Date(isWp ? data.modified : data.sys.updatedAt).toLocaleString(
          [],
          {
            day: 'numeric',
            year: 'numeric',
            month: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          },
        )}
      </td>
      <td className="align-middle">
        {isWp
          ? data.status === 'draft'
            ? 'Draft'
            : 'Post'
          : status.launch
          ? 'Published'
          : 'Draft'}
      </td>
      <td className="align-middle">
        <Button
          color="cpblue-500"
          className="rounded-circle d-block mx-auto"
          onClick={() => window.open(buildLink(status), '_blank')}
        >
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        </Button>
      </td>
    </tr>
  );
}

export default WebStatusRow;
